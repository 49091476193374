import React, { useState } from "react";

import { generateUserDocument } from "services/Firebase/firebase"
import { auth } from "services/Firebase/firebase";

import * as ROUTES from 'components/RouterComponents/Routes';
import history from "components/RouterComponents/history";

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Header from "components/HeaderComponents/Header";
import './SignUpPage.css'
import 'App.scss';
import { Field, Control, Label, Input} from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';

const SignUp = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [company, setUserCompany] = useState("");
    const [telephone, setUserTelephone] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);

    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
        event.preventDefault();
        try {
            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            await user.updateProfile({ displayName: displayName });
            generateUserDocument(user, displayName);
            history.push(ROUTES.LANDING);
        }
        catch (error) {
            console.log(error);
            setError('Error Signing up with email and password');
        }
        setUserCompany("");
        setEmail("");
        setPassword("");
        setDisplayName("");
        setUserTelephone("");
    };

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;  // currentTarget has name and value as components
        if (name === "userEmail") {
            setEmail(value);
        } else if (name === "userPassword") {
            setPassword(value);
        } else if (name === "displayName") {
            setDisplayName(value);
        } else if (name === "userCompany") {
            setUserCompany(value);
        } else if (name === "userTelephone") {
            setUserTelephone(value);
        }
    };

    return (
        <div>
            <Header />
            <Box style={{ margin: 50 }}>
            <Field>
                <Heading>Sign Up</Heading>
            </Field>

            {error !== null && 
                <Field>
                    <Label style={{color: 'red'}} >
                        {error}
                    </Label>
                </Field>
            }

            <Field>
                <Label>
                    Display Name:
                </Label>
                <Control>
                    <Input
                        type="text"
                        name="displayName"
                        value={displayName}
                        placeholder="E.g: Marcelo"
                        id="displayName"
                        onChange={event => onChangeHandler(event)}
                    />
                </Control>
            </Field>

            <Field>
                <Label>
                    Email:
                    </Label>
                <Control>
                    <Input
                        type="email"
                        name="userEmail"
                        value={email}
                        placeholder="E.g: mcanga@ultraoc.com"
                        id="userEmail"
                        onChange={event => onChangeHandler(event)}
                    />
                </Control>
            </Field>

            <Field>
                <Label>
                    Password:
                    </Label>
                <Control>
                    <Input
                        type="password"
                        name="userPassword"
                        value={password}
                        placeholder="Your Password"
                        id="userPassword"
                        onChange={event => onChangeHandler(event)}
                    />
                </Control>
            </Field>

            <Field>
                <Label>
                    Company:
                    </Label>
                <Control>
                    <Input
                        type="text"
                        name="userCompany"
                        value={company}
                        placeholder={"Your Company"}
                        id="userCompany"
                        onChange={event => onChangeHandler(event)}
                    />
                </Control>
            </Field>

            <Field>
                <Label>
                    Telephone:
                    </Label>
                <Control>
                    <Input
                        type="tel"
                        name="userTelephone"
                        value={telephone}
                        placeholder={"Telephone"}
                        id="userTelephone"
                        onChange={event => onChangeHandler(event)}
                    />
                </Control>
            </Field>

            <Field>
                <Button
                    onClick={event => {
                        createUserWithEmailAndPasswordHandler(event, email, password);
                    }}
                    color="primary"
                >
                    Sign up
                </Button>
            </Field>

            <p>or</p>

            {/* <Field>
                <Button color="link">
                    Sign In with Google
                </Button>
            </Field> */}

            <Field>
                Already have an account?{" "}
                <Link to={ROUTES.SIGN_IN}>
                    Sign in here
                </Link>
            </Field>

        </Box>
        </div>

    );
};

export default withRouter(SignUp);
