import React from "react";
import { Redirect } from "react-router-dom";
import "./LogOutPage.css"
import {auth} from "services/Firebase/firebase";

import * as ROUTES from 'components/RouterComponents/Routes'

function LogOutPage() {

    auth.signOut()

    return <Redirect to={ROUTES.LANDING} />

}
export default LogOutPage;