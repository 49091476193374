import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {auth} from "services/Firebase/firebase";

import * as ROUTES from 'components/RouterComponents/Routes'
import history from "components/RouterComponents/history";

import Header from "components/HeaderComponents/Header";
import 'App.scss';
import { Field, Control, Label, Input} from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';

const SignIn = () => {

    const [email, setEmail]       = useState('');
    const [password, setPassword] = useState('');
    const [error, setError]       = useState(null);
    
    const signInWithEmailAndPasswordHandler = (event, email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password)
        .then( ()=> {
            history.push(ROUTES.LANDING);
        })
        .catch(error => {
            setError("Error signing in with password and email!");
            console.error("Error signing in with password and email", error);
        });

  
    };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <div>
            <Header />
            <Box style={{ margin: 50 }}>
                <Field>
                    <Heading>Sign In</Heading>
                </Field>

                {error !== null && <div >{error}</div>}

                <Field>
                    <Label> Email:</Label>
                    <Control>
                        <Input
                            type="email"
                            name="userEmail"
                            value={email}
                            placeholder="E.g: mcanga@ultraoc.com"
                            id="userEmail"
                            onChange={(event) => onChangeHandler(event)}
                        />
                    </Control>
                </Field>

                <Field>
                    <Label>Password:</Label>
                    <Control>
                        <Input
                            type="password"
                            name="userPassword"
                            value={password}
                            placeholder="Your Password"
                            id="userPassword"
                            onChange={(event) => onChangeHandler(event)}
                        />
                    </Control>
                </Field>

                <Field>
                    <Button color="primary" onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}>
                        Sign in
                    </Button>
                </Field>

                <p>or</p>

                {/* <Field>
                    <Button color="link">
                        Sign in with Google
                </Button>
                </Field> */}

                <Field>
                    <Label>
                    Don't have an account? {" "}
                    <Link to={ROUTES.SIGN_UP} >
                        Sign up here
                    </Link>
                    </Label>
                </Field>

                <Field>
                    <Label>
                    <Link to={ROUTES.PASSWORD_FORGET}>
                        Forgot Password?
                    </Link>
                    </Label>
                </Field>
            </Box>
        </div>
    );
};

export default SignIn;
