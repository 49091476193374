import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB68pIC6FhAiCjQZ_kUaSFoimRpUrSK9PU",
    authDomain: "surgemarine.firebaseapp.com",
    databaseURL: "https://surgemarine.firebaseio.com",
    projectId: "surgemarine",
    storageBucket: "surgemarine.appspot.com",
    messagingSenderId: "441932163663",
    appId: "1:441932163663:web:80c1a91fe8b9240fc9d83a"
};

const provider = new firebase.auth.GoogleAuthProvider();




firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};


export const checkUser = () => {
    let user = firebase.auth().currentUser;
        if (user) {
            console.log("User is signed in");
            return true;
        } else {
            console.log("User is not signed in")
            return false;
        }
}


export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const email = user.email;
        const displayName = user.displayName;
        console.log("email: ", email);
        console.log("displayName: ", displayName);
        try {
            await userRef.set({
                displayName,
                email,
            });
        } catch (error) {
            console.error("Error creating user document", error);
            console.log(error);
        }
    }
    return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
        console.log(error);
    }
};
