import React, { useState, useContext } from 'react';
import './Header.css'

import * as ROUTES from '../RouterComponents/Routes';
import { authContext } from "services/Firebase/UserProvider"

import Navbar from 'react-bulma-components/lib/components/navbar';
import logo from 'assets/logo_surge.png'

const GenerateLoginButton = () => {

    const { user, email } = useContext(authContext);

    if (user === null) {
        return (
            <Navbar.Item href={ROUTES.SIGN_IN}>
                Sign In
            </Navbar.Item>
        );
    } else {
        return (
            <Navbar.Item href={ROUTES.LOGOUT}>
                Logout {email}
            </Navbar.Item>
        );
    }
}

const Header = () => {

    const [isActive, setisActive]  = useState(false);
 
    return (
        <Navbar active={isActive}>

            <Navbar.Brand>
                <Navbar.Item renderAs="a" href={ROUTES.LANDING}>
                    <img src={logo} alt="Surge, a Marine Operation Software" />
                </Navbar.Item>
                <Navbar.Burger active={isActive.toString()} 
                               onClick = {() => { setisActive(!isActive); }}
                />
            </Navbar.Brand>

            <Navbar.Menu >
                <Navbar.Container>

                    <Navbar.Item href={ROUTES.LANDING}>
                            Home
                    </Navbar.Item>

                    {/* <Navbar.Item href={ROUTES.ABOUT}>
                            About
                    </Navbar.Item>

                    <Navbar.Item href={ROUTES.NEWS}>
                            News
                    </Navbar.Item> */}



 

                   <Navbar.Item dropdown hoverable href="#">
                        <Navbar.Link arrowless={false}>
                            Documents
                        </Navbar.Link>
                        <Navbar.Dropdown>
                            <Navbar.Item href="https://aws-surge-marine-01.s3.us-east-2.amazonaws.com/public/doc/qualif/qualif.pdf">
                                Statement of Qualifications
                            </Navbar.Item>
 
                            <Navbar.Item href="https://aws-surge-marine-01.s3.us-east-2.amazonaws.com/public/doc/user/index.html">
                                Developer Reference Manual
                            </Navbar.Item>
                            {/* <Navbar.Item href="https://aws-surge-marine-01.s3.us-east-2.amazonaws.com/public/doc/qualif/mcanga_resume.pdf">
                                Resume
                            </Navbar.Item> */}
                       </Navbar.Dropdown>
                    </Navbar.Item>

                    <Navbar.Item href={ROUTES.DOWNLOADS}>
                            Downloads
                    </Navbar.Item>

                    <Navbar.Item href={ROUTES.CONTACT}>
                            Contact
                    </Navbar.Item>

                </Navbar.Container>

                <Navbar.Container position="end">
                    <GenerateLoginButton />
                </Navbar.Container>

            </Navbar.Menu>

        </Navbar>
    );
}

export default Header;