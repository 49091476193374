import React, { useState } from "react";
import {auth} from "services/Firebase/firebase";

import * as ROUTES from 'components/RouterComponents/Routes'
import history from "components/RouterComponents/history";

import Header from "components/HeaderComponents/Header";
import { Control } from "react-bulma-components/lib/components/form";
import { Field,  Label, Input} from 'react-bulma-components/lib/components/form';
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';

const PasswordReset = () => {
    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        }
    };

    const sendResetEmail = event => {
        event.preventDefault();
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true);
                setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
                history.push(ROUTES.SIGN_IN);
            })
            .catch(() => {
                setError("Error resetting password");
            });
    };
    
    return (
        <div>
            <Header/>
            <Box style={{ margin: 50 }}>
                <Field>
                    <Heading>Reset your Password</Heading>
                </Field>

                <Field>
                {error !== null && <div>{error}</div>}
                </Field>
            
                <Field>
                    {emailHasBeenSent && (
                        <div>
                            An email has been sent to you!
                        </div>
                    )}
                </Field>

                <Field>
                    <Label> Email</Label>
                    <Control>
                    <Input
                        type="email"
                        name="userEmail"
                        id="userEmail"
                        value={email}
                        placeholder="Input your email"
                        onChange={onChangeHandler}
                    />
                    </Control>
                </Field>

                <Field>
                    <Button color="primary"  onClick={sendResetEmail}>
                        Send me a reset link
                    </Button>
                </Field>

                {/* <Field>
                <Label>
                <Link
                    to ="/"
                >
                    &larr; back to sign in page
                </Link>
                </Label>
                </Field> */}
                        
        </Box>
    </div>
    );
};

export default PasswordReset;