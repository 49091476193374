import React from 'react';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react';


export default props => {
  
  const {video,muted=true,autoPlay=true,poster="logo_surge.png",loop=true} = props;
 
  return (
    
    <div style={{borderRadius: "10px",overflow: "hidden" }}>
    <Player  muted={muted} autoPlay={autoPlay} loop={loop} poster={require(`assets/${poster}`)}>
      <source src={require(`assets/${video}`)} />
      <ControlBar>
        <ReplayControl seconds={10} order={1.1} />
        <ForwardControl seconds={30} order={1.2} />
        <CurrentTimeDisplay order={4.1} />
        <TimeDivider order={4.2} />
        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
        <VolumeMenuButton vertical />
      </ControlBar>
    </Player>
    </div>
  );
};