import "../Home/Home.css"
import React from "react";
import Header from "components/HeaderComponents/Header";
import GenerateDownloads from "./GenerateDownloads";
function Downloads() {
    return (
        <div>
            <Header/>
            <GenerateDownloads/>
        </div>
    );
}
export default Downloads;