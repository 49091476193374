import React from "react";
import {auth} from "services/Firebase/firebase";
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Box from 'react-bulma-components/lib/components/box';
import Content from 'react-bulma-components/lib/components/content';
class GenerateDownloads extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }

    componentDidMount() {
        this.setState({user: auth.currentUser});
    }

    render() {
        if (!this.state.user) {
            return(
                <Box style={{ margin: 50 }}>
                <Content size="large">
                    <Heading>Surge Distribution</Heading>
                    </Content>

                    <Content size="small">
                
                    <Heading subtitle>Window download</Heading>
                    <a href="https://aws-surge-marine-01.s3.us-east-2.amazonaws.com/public/surge/builder_0.87_200531_win.zip" download>
                        builder_0.87_200531_win.zip
                        
                 </a></Content>
                    <Content size="small">
                    <Heading subtitle>MacOs download</Heading>
                    <a href="https://aws-surge-marine-01.s3.us-east-2.amazonaws.com/public/surge/builder_0.87_200531_mac_64.zip" download>
                        builder_0.87_200531_mac_64.zip
                    </a>
                </Content>
            </Box>
            );
        } else {
            return(
                <div>
                    <h1 style={{textAlign: "center"}}>Here are the downloads</h1>
                </div>    
            );
        }
    }
}
export default GenerateDownloads;