import React from 'react';
import { Router, Route } from 'react-router-dom';
import './App.css';

 import List from  'pages/About/List';
import './App.scss';

// import {Router,Route } from 'react-router-dom';
import * as ROUTES from './components/RouterComponents/Routes';
import history from 'components/RouterComponents/history';

import About       from "./pages/About/About";
import Home        from "./pages/Home/Home";
import Downloads   from "./pages/Downloads/Downloads";
import AdminPage   from "./pages/LoginPages/AdminPage";
import SignUpPage  from "./pages/LoginPages/SignUpPage";
import SignInPage  from "./pages/LoginPages/SignInPage";
import PasswordForgotPage from "./pages/LoginPages/PasswordForgotPage";
import News         from "./pages/News/News";
import UserProvider from "./services/Firebase/UserProvider";
import LogOutPage   from "./pages/LoginPages/LogOutPage";
import Contact      from "./pages/Contact/Contact";

import HttpsRedirect from 'react-https-redirect';


    const App = () => (

    <UserProvider>
    <HttpsRedirect>
    <Router history={history}>
        <Route exact path = {ROUTES.LANDING} component={Home}/>
        <Route exact path = {ROUTES.DOWNLOADS} component={Downloads}/>
        <Route exact path = {ROUTES.SIGN_IN} component={SignInPage}/>
        <Route exact path = {ROUTES.SIGN_UP} component={SignUpPage}/>
        <Route exact path = {ROUTES.PASSWORD_FORGET} component={PasswordForgotPage}/>
        <Route exact path = {ROUTES.ADMIN} component={AdminPage}/>
        <Route exact path = {ROUTES.NEWS} component={News}/>
        <Route exact path = {ROUTES.LOGOUT} component={LogOutPage}/>
        <Route exact path = {ROUTES.ABOUT} component={About}/>
        <Route       path = {ROUTES.CONTACT} component={Contact}/>
        <Route path='/list' component={List}/> 
    </Router>
    </HttpsRedirect>
    </UserProvider>

    );


export default App;
