
import React from "react";
import Header from "components/HeaderComponents/Header";

import { Link } from 'react-router-dom';

function About() {

    

    
    return (
         
        <div>
            <Header />
      <h1>Project Home</h1>
      {/* Link to List.js */}
      <Link to={'./list'}>
        <button variant="raised">
            My List
        </button>
      </Link>

        </div>
    );
}
export default About;
