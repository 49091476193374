import React from 'react';

import { Carousel as ReactCarousel } from 'react-responsive-carousel';

import p116  from 'assets/116.jpg'
import art_b from 'assets/art_b.jpg'
import dsc_0730 from 'assets/dsc_0730.jpg'
import dsc_1387 from 'assets/dsc_1387.jpg'
import hull_tow from 'assets/hull_tow_out_430.jpg'
import img_1916 from 'assets/IMG_1916.jpg'
import kbridg03 from 'assets/kbridg03.png'
import mafumeira from 'assets/mafumeira_topsides.jpg'
import p4140008 from 'assets/p4140008.jpg'
import sak from 'assets/sak.jpg'
import sals_ln1 from 'assets/sals_ln1.png'
import upending from 'assets/upending.png'
import sp_tow from 'assets/sp_tow.jpg'
import windmill from 'assets/windmill.jpg'
import 'components/Container/Carousel.css'
//import useWindowDimensions from 'components/Tool/GetWindowDimension'

// showArrows: Default value set to true, displays previous and next arrows.
// showStatus: Default value set to true, displays current item’s index.
// showIndicators: Default value set to true, displays small dots below the with links to change the items in the Carousel.
// showThumbs: Default value set to true, shows assets for the thumbnails.
// thumbWidth: Default value is undefined, optionally specify pixel width of a thumbnail to avoid calculating values.
// infiniteLoop: Adds infinite loop in carousel, default value set to false.
// selectedItem: Declares the starting selected item.
// axis: Converts the orientation horizontal or vertical, default value is horizontal.
// onChange: Triggered when updating the positions.
// onClickItem: Triggered when an item is clicked.
// onClickThumb: Triggered when a thumbnail is clicked.
// stopOnHover: Default value set to true, Stops auto play when mouse is over the carousel.
// interval: Default value set to 3000, Adds time interval in auto play.
// transitionTime: Default value set to 350, Time interval in milliseconds between slide transition.
// swipeable: Default is set to true, allows swiping gestures.
// dynamicHeight: Default is set to false, manages the carousel height if needed. It does not work with vertical axis.
// centerMode: Default is set to false, allows centered view with partial previous and next slides. It only works with horizontal axis.
// labels: Optionally specify labels to be added to controls.
// onSwipeStart: Triggered when a swiping gesture has initialized.
// onSwipeEnd: Triggered when a swiping gesture has completed.
// onSwipeMove: Triggered when a swiping gesture is happening.

// centerSlidePercentage	number	80	optionally specify percentage width (as an integer) of the slides in centerMode
// interval	number	5000	Interval of auto play
// transitionTime	number	350	Duration of slide transitions (in miliseconds)
// width	string	-	Allows to set a fixed width


export default function Carousel(){

    // const { height, width } = useWindowDimensions();
    // var mywidth  = 0.8 * width;
    // var myheight = 0.4 * mywidth;

    //const styles = StyleSheet.create({

    //     responsiveImage: {
    //       width: '100%',
    //       // Without height undefined it won't work
    //       height: undefined,
    //       // figure out your image aspect ratio
    //       aspectRatio: 135 / 76,
    //     },
      
    //   });

    return(
    <ReactCarousel infiniteLoop useKeyboardArrows autoPlay centerMode showThumbs={false} emulateTouch={true}  >
        
    
      <div >
            <img alt="" src={art_b}   className="carousel" />
        </div>
        <div >
            <img alt="" src={dsc_0730} className="carousel" />
        </div>

               <div>
            <img alt="" src={dsc_1387} className="carousel" />
        </div>
        <div>
            <img alt="" src={hull_tow} className="carousel" />
        </div>
        <div>
            <img alt="" src={img_1916} className="carousel" />
        </div>
        <div>
            <img alt="" src={kbridg03} className="carousel" />
        </div>
        <div>
            <img alt="" src={mafumeira} className="carousel" />
        </div>
        <div>
            <img alt="" src={p4140008} className="carousel" />
        </div>
        <div>
            <img alt="" src={sak}      className="carousel" />
        </div>
        <div>
            <img alt="" src={sals_ln1} className="carousel" />
        </div>
        <div>
            <img alt="" src={upending} className="carousel" />
        </div>
        <div>
            <img alt="" src={sp_tow} className="carousel" />
        </div>
        <div>
            <img alt="" src={p116} className="carousel" />
        </div>
        <div>
            <img alt="" src={windmill} className="carousel" />
        </div>

    </ReactCarousel>

    );
    }

