export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const DOWNLOADS = '/downloads';
export const ADMIN = '/admin';
export const CONTACT = '/contact';
export const NEWS = '/news';
export const ABOUT = '/about';
export const PASSWORD_FORGET = '/pw_forget';
export const LOGOUT = '/signout';