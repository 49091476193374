import React, { useState,useEffect} from "react";
import Header from "components/HeaderComponents/Header";
import Button from 'react-bulma-components/lib/components/button';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import { Textarea, Field, Control, Label, Input,InputFile} from 'react-bulma-components/lib/components/form';
import Icon from 'react-bulma-components/lib/components/icon';
import axios from 'axios';


const Contact = props => {

    const [email,       setEmail]       = useState("");
    const [displayName, setDisplayName] = useState("");
    const [fileName,    setFileName]    = useState("");
    const [userText,    setTextName]    = useState("");
    const [diag,        setDiag]        = useState("");

    const onSubmitHandler = event => {

        event.preventDefault();

        const emailForm = {
            email,
            displayName,
            userText,
            fileName,
        };

        axios
            .post('/contact', emailForm)
            .then( response => {
                setDiag(response.data);
            }).catch(err => {
                setDiag(err.data);
                console.error(err.data);
            });

          
    };


    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;  // currentTarget has name and value as components
        if (name === "userEmail") {
            setEmail(value);
        } else if (name === "fileName") {
            setFileName(value);
        } else if (name === "displayName") {
            setDisplayName(value);
        } else if (name === "userText") {
            setTextName(value);
        }
    };


    return (
        <div>
            <Header />

            <Box style={{ margin: 50 }}>

                <Field>
                    <Heading>Send an Email to the Surge Team</Heading>
                </Field>

 
                <Field>
                    <Label> Your Name:</Label>
                    <Control>
                        <Input
                            type="text"
                            name="displayName"
                            value={displayName}
                            placeholder="E.g: marcelo"
                            onChange={(event) => onChangeHandler(event)}
                        />
                    </Control>
                </Field>

                <Field>
                    <Label> Your Email:</Label>
                    <Control>
                        <Input
                            type="email"
                            name="userEmail"
                            value={email}
                            placeholder="E.g: mcanga@ultraoc.com"
                            onChange={(event) => onChangeHandler(event)}
                        />
                    </Control>
                </Field>

                <Field>
                    <Label>Message</Label>
                    <Control>
                        <Textarea 
                            value={userText}
                            name="userText"
                            id="userText"
                            onChange={(event) => onChangeHandler(event)}
                        />
                    </Control>
                </Field>

                <Field>
                    <Label>File</Label>
                    <Control>
                        <InputFile icon={<Icon icon="upload" />} boxed placeholder="Attachment" />
                    </Control>
                </Field>

                <Field>
                    <Button color="primary" onClick={(event) => onSubmitHandler(event)}>
                        Send
                    </Button>
                </Field>

 
            <div>
                {diag}
            </div>

            </Box>

        </div>
    );
}

export default Contact;