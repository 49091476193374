import React from "react";
import "./Home.css"
import Header from "components/HeaderComponents/Header";
import Carousel from 'components/Container/Carousel';
import Video    from 'components/Container/VideoSurge'
import Example  from 'components/Container/VideoHtml'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Section from 'react-bulma-components/lib/components/section';
import Content from 'react-bulma-components/lib/components/container';
import Heading from 'react-bulma-components/lib/components/heading';
import Background from 'assets/waves.jpg';
import Columns from 'react-bulma-components/lib/components/columns';
import Notification from 'react-bulma-components/lib/components/notification';


function Home() {

    var sectionStyle = {
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: "grayscale(30%)",
    };
    var headingStyle = {
        textAlign: 'center',
        paddingTop: "7vh",
        paddingBottom: "7vh",
 };
    var notificationStyle = {
        color: 'white',
        paddingTop: "7vh",
        paddingBottom: "5vh",
        backgroundColor: "rgba(0,0,0,.0)",
 };

    return (
        <div>
            <Header/>

            <div style={sectionStyle}>
            <Content>
                <Columns>
                    <Columns.Column>
                        <Content style={headingStyle}>
                        <Heading size={1} style={{color:'white'}} >
                           Welcome to Surge 
                        </Heading>
                        <Heading size={2} style={{color:'white'}} >
                           Marine Operations Made Simple  
                        </Heading>
                        </Content>
                    </Columns.Column>
                </Columns>
                </Content>
                <Content>
                <Columns>
                    <Columns.Column size={8} offset={2}>
                    <Video video="Surge-clips-web.mp4" poster="SurgeScreenMain.png" />
                     
                        <Notification  style={notificationStyle}>

                        Surge is a marine operation simulator that assists offshore professionals with 
                        the selection of vessel, rigging, and crane capacity. The tool produces all the technical 
                        information required to develop modern offshore engineering projects. The tool is very simple to use 
                        and requires minimal training.

                        </Notification>
                    </Columns.Column>
                </Columns>
            </Content>
            </div>
            <Section>
      <Columns>
      <Columns.Column size={4}>
      <Heading>Bringing value to marine operations</Heading>
 
      This short video explains the value that Surge brings to offshore projects.
The video shows how quickly  and easy marine engineering can be delivered when key information is used
from the software databases.

In a nutshell, we are reducing the typical delivery time of marine engineering from
months to a week for first setup, and hours for similar scenarios.

The tool can assist ongoing marine operations remotely in real time.
The reporting, html based, is automatically generated and fits well with network technology.

      </Columns.Column>
      <Columns.Column >
      {/* <YouTube videoId="gku241-JQLQ" opts={opts} />   */}
      <Video video="Surge-short.mp4" muted={false} autoPlay={false}  poster="SurgeScreenVideo.png"/>
       </Columns.Column>
      </Columns>
    </Section>

    <Section>
      <Columns>
      <Columns.Column size={4}>
      <Heading>Surge general overview</Heading>
      This video provides a general overview of the marine engineering software.
      The following  topics are discussed:
              <table>
                <tr>
                  <td>-Software installation in Windows and MacOS</td>
                </tr>
                <tr>
                  <td>- InPlace Module</td>
                </tr>
                <tr>
                  <td>-The Signature File</td>
                </tr>
                <tr>
                  <td>- Floatover</td>
                </tr>
                <tr>
                  <td>- Hardware Lowering</td>
                </tr>
                <tr>
                  <td>- Tow</td>
                </tr>
                <tr>
                  <td>- Ballast</td>
                </tr>
                <tr>
                  <td>- Modeler</td>
                </tr>
                <tr>
                  <td>- Jacket Launch and Catamaran</td>
                </tr>
              </table>
      </Columns.Column>
      <Columns.Column>
      {/* <YouTube videoId="LC_RPMiDG6Y" opts={opts} />   */}
      <Video video="Surge-long.mp4" muted={false} autoPlay={false} poster="SurgeScreenVideo.png"/>   
      </Columns.Column>
      </Columns>
    </Section>

            <Section><Content>
            <Carousel/>
            </Content></Section>
 
        </div>        
    );
}
export default Home;