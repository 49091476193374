import React, { createContext, useState, useEffect } from "react";
import {auth}                 from "services/Firebase/firebase"

export const authContext  =  createContext("from init");

const UserProvider = props => {

    const [user,   setUser]    = useState({user:null});

    var email,display;
    var email_verified = false;
    
     if(user !== null){
         display        = user.displayName;
         email          = user.email;
         email_verified = user.emailVerified;
     }

    const userValue = {      // well defined object, finally
         user,
         email,
         display,
         email_verified
     }


    useEffect(  () => {
        auth.onAuthStateChanged((user) =>{
            
            setUser(user);

            if( user !== null && (! user.emailVerified )){
            user
            .sendEmailVerification()
            .then(function() {
                // email sent
            })
            .catch(function(error) {
                // An error happened. 
            });

            }
    })},[]
    )

    
    return (
        <authContext.Provider  value={userValue}>
            {props.children}
        </authContext.Provider>
    );
}

export default UserProvider;

